<template>
  <div class="themeImageUploader col-12 col-xl-3 mb-3">
    <Portlet
      :title="$t('themeImageUploaderComponent.upload')"
      icon="upload"
    >
      <div class="input-group mb-3">
        <div class="input-prepend mr-3">
          <label style="width:50px;">{{ $t('staticResourceUploaderComponent.file') }}</label>
        </div>
        <input
          ref="fileInput"
          type="file"
          class="form-control cursor-pointer rounded"
          @change="getFileFromInput($event)"
        >
      </div>
            
      <div
        v-if="uploadPercentage > 0"
        class="input-group mb-3"
      >
        <div class="input-prepend mr-3">
          <label style="width:50px;">{{ $t('staticResourceUploaderComponent.progress') }}</label>
        </div>
        <div class="progress">
          <div
            :aria-valuenow="uploadPercentage"
            :style="`width:${ uploadPercentage }%;`"
            class="progress-bar"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {{ uploadPercentage }} %
          </div>
        </div>
      </div>
            
      <template v-if="uploadPercentage == 0">
        <button
          v-if="image != null"
          class="btn btn-primary btn-block"
          @click="saveFile()"
        >
          <font-awesome-icon
            class="mr-1"
            icon="upload"
          />
          <span>
            {{ $t('upload') }}
          </span>
        </button>
        <button
          v-else
          class="btn btn-primary btn-block cursor-not-allowed"
          disabled
        >
          <font-awesome-icon
            class="mr-1"
            icon="upload"
          />
          <span>
            {{ $t('upload') }}
          </span>
        </button>
      </template>
    </Portlet>
  </div>
</template>

<script>
export default {
  name: "ThemeImageUploader",
  props: {
    layoutImageId: {
      type: String,
      required: false,
      default: null
    },
    userDependentImageId: {
      type: String,
      required: false,
      default: null
    },
    fileIsAvailable: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      uploadingRightNow: false,
      uploadPercentage: 0,
      loading: true,
      image: null,
      imageName: '',
      imageUrl: ''
    }
  },
  created () {
    if(this.fileIsAvailable)
    {
      this.getImageRessource();
    }
  },
  methods: {
    getFileFromInput () {
      this.image = this.$refs.fileInput.files[0];
      this.imageName = this.$refs.fileInput.files[0].name;
      this.uploadPercentage = 0;
    },
    async uploadImage () {
      this.uploadingRightNow = true;
      this.uploadPercentage = 0;
      let bodyFormData = new FormData();
      bodyFormData.append('formFile', this.image);

      let uploadRoute = '';
      if(this.layoutImageId)
      {
        uploadRoute=`/VideoPostProcessingResource/UploadLayoutImage/${ this.layoutImageId }`;
      }
      else if(this.userDependentImageId)
      {
        uploadRoute=`/VideoPostProcessingResource/UploadUserDependentImage/${this.userDependentImageId }`;
      }
      await this.axios.post(uploadRoute, 
        bodyFormData,
        { headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: function ( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
          }.bind(this)
        }
      )
      .then((response) => {
        if (response && response.status && response.status == 200) {
          this.$snotify.success(this.$t('staticResourceUploaderComponent.fileSavedMessage'), this.$t('staticResourceUploaderComponent.fileSavedTitle'));
        }
      })
      .finally(() => {
        this.uploadingRightNow = false;
        setTimeout(() => {
          this.uploadPercentage = 0;
        }, 2500);
      });

    },
    getImageRessource () {
      let getImageRoute = '';
      if(this.layoutImageId)
      {
        getImageRoute=`/VideoPostProcessingResource/GetLayoutImage/${ this.layoutImageId }`;
      }
      else if(this.userDependentImageId)
      {
        getImageRoute=`/VideoPostProcessingResource/GetUserDependentImage/${this.userDependentImageId }`;
      }
      this.axios.get(getImageRoute)
        .then((response) => {
          if (!response) {
            return;
          }
          if (!response.data) {
            return;
          }
          this.imageUrl = response.data.url;
          this.$emit("imageUrlUpdated", this.imageUrl);        
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async saveFile () {
      await this.uploadImage();
      this.getImageRessource();
    }
  }
}
</script>

<style scoped>
 .themeImageUploader input[type="file"] {
  height: 40px;
  padding-top: 8px;
  padding-left: 8px;
}
.themeImageUploader .previewImage {
  width: 100%;
  height: auto;
  display: block;
}
.themeImageUploader .placeholderIcon {
  font-size: 2rem;
}
.themeImageUploader .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>
